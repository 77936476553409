<template>
  <div>
    <p class="pyt"><strong>7.</strong>Czas trwania jednej zmiany spawacza</p>
    <div class="inputtxtbox">
        <input type="text" v-model="odp" class="form-control" placeholder="0" ref="input"> <span>h</span>
    </div>
    <div class="dalejbox" v-show="gotowe"><button class="btn btn-primary" @click="setOdp">OBLICZ</button></div>      
  </div>
</template>
<script>
export default {
  name: 'Pytanie6Component',
   data() {
      return {
          odp: 8,
      }
  },
   computed: {
    gotowe() {
      return this.odp>0;
    }
  },
  methods: {
    setOdp: function() { 
      if (Number.isInteger(parseInt(this.odp)))
      this.$emit('clicked', this.odp);
    },
    setFocus: function() {
      this.$refs.input.focus();
    }
  },
  mounted: function() {    
        this.setFocus();   
  }
}
</script>