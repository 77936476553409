<template>
   <transition name="wslide" appear>
        <div class="finans-box">
            <span class="close-modal" @click="$emit('closeModal')">
                <svg version="1.1" viewBox="0 0 512.001 512.001" width="24" xml:space="preserve">
                    <g>
                        <path d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717
                            L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859
                            c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287
                            l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285
                            L284.286,256.002z"/>
                    </g>
                </svg>
            </span>
             <div v-show="czypokazac1"><h1>Ramię robota współpracującego YASKAWA</h1>
             <div class="d-flex">
                 <div class="flex-left"><img class="img-responsive" src="images/red.jpg" alt="RedDot Winner 2020" /></div> 
                 <div class="flex-right"><b>MOTOMAN HC10DT-IP67</b> 6-osiowy robot umożliwiający współpracę z człowiekiem. Został on zaprojektowany w taki sposób, aby zapewnić maksymalną ochronę przy kontakcie z operatorem. Bezpieczeństwo to zapewnia technologia „Power and Force Limit”, która to w momencie każdorazowego nawet minimalnego zetknięcia się robota z człowiekiem zatrzymuje go. </div>
             </div>
             <p class="mbottom15 ptop15">
                 Jest również zgodny z normą EN 60529-IP67, dzięki czemu jego wydajność podczas pracy nie spada nawet na moment w bardzo trudnych warunkach .
             </p>
             <p><b>Dane techniczne:</b></p>
             <table class="table mytable">
                 <tr>
                     <td colspan="2">Liczba osi</td>
                     <td>6</td>
                 </tr>
                 <tr>
                     <td colspan="2">Udźwig</td>
                     <td>10 kg</td>
                 </tr>
                 <tr>
                     <td colspan="2">Maksymalny zakres roboczy</td>
                     <td>1200 mm</td>
                 </tr>
                 <tr>
                     <td colspan="2">Powtarzalność</td>
                     <td>±0,1 mm</td>
                 </tr>
                 <tr>
                     <td colspan="2">Waga</td>
                     <td>58 kg</td>
                 </tr>
                 <tr class="lastrow">
                     <td colspan="2">Źródło zasilania</td>
                     <td>1 kVA</td>
                 </tr>

                 <tr class="sep"><td colspan="3"></td></tr>

                 <tr>
                     <td rowspan="6">Zakres ruchu</td>
                     <td>Oś S</td>
                     <td>-180 do +180<sup>o</sup></td>
                 </tr>
                 <tr>
                     
                     <td>Oś L</td>
                     <td>-180 do +180<sup>o</sup></td>
                 </tr>
                 <tr>
                     
                     <td>Oś U</td>
                     <td>-95 do +265<sup>o</sup></td>
                 </tr>
                 <tr>
                     
                     <td>Oś R</td>
                     <td>-180 do +180<sup>o</sup></td>
                 </tr>
                 <tr>
                     
                     <td>Oś B</td>
                     <td>-180 do +180<sup>o</sup></td>
                 </tr>
                 <tr class="lastrow">
                     
                     <td>Oś T</td>
                     <td>-180 do +180<sup>o</sup></td>
                 </tr> 

                 <tr class="sep"><td colspan="3"></td></tr>  

                 
                 <tr>
                     <td rowspan="6">Maksymalna prędkość</td>
                     <td>Oś S</td>
                     <td>2,27 rad/s  (130 <sup>o</sup>/s)</td>
                 </tr>
                 <tr>
                     
                     <td>Oś L</td>
                     <td>2,27 rad/s  (130 <sup>o</sup>/s)</td>
                 </tr>
                 <tr>
                     
                     <td>Oś U</td>
                     <td>3,14 rad/s  (180 <sup>o</sup>/s)</td>
                 </tr>
                 <tr>
                     
                     <td>Oś R</td>
                     <td>3,14 rad/s  (180 <sup>o</sup>/s)</td>
                 </tr>
                  <tr>
                     
                     <td>Oś B</td>
                     <td>4,36 rad/s  (250 <sup>o</sup>/s)</td>
                 </tr>
                  <tr class="lastrow">
                     
                     <td>Oś T</td>
                     <td>4,36 rad/s  (250 <sup>o</sup>/s)</td>
                 </tr>

                 <tr class="sep"><td colspan="3"></td></tr>

                <tr>
                     <td rowspan="5">Warunki pracy</td>
                     <td>Temperatura</td>
                     <td>0 do 40 <sup>o</sup>C</td>
                 </tr>
                 <tr>
                     
                     <td>Wilgotność</td>
                     <td>20% do 80% (bez kondensacji)</td>
                 </tr>
                 <tr>
                     
                     <td>Drgania</td>
                     <td>4,9 m/s<sup>2</sup> lub mniej</td>
                 </tr>
                 <tr>
                     
                     <td>Wysokość</td>
                     <td>1000 mm lub mniej</td>
                 </tr>
                  <tr>
                     
                     <td>Inne</td>
                     <td>
                         <ul><li>Wolne od kurzu, sadzy i oleju;</li>
<li>Wolne od wybuchających i żrących gazów lub cieczy;</li>
<li>Wolne od oddziaływania silnych pól magnetycznych;</li></ul>
                     </td>
                 </tr>
                 


             </table>
    <div style="margin-bottom: 60px;">Więcej szczegółowych informacji znajdziesz na stronie<br><a style="color:#0065b2" target="_blank" rel="nofollow" href="https://www.yaskawa.pl/systems/system-engineering/productdetail/product/weld4me_10286">https://www.yaskawa.pl/systems/system-engineering/productdetail/product/weld4me_10286</a></div>

             </div>

             <div v-show="czypokazac2"><h1>Urządzenie sterujące</h1>
                <div class="d-flex">
                    <div class="flex-left"><img src="/images/sterownik.png" alt="kontroler" /></div>
                    <div class="flex-right">
                        <p><b>MOTOMAN YRC1000</b> to kontroler charakteryzujący się niebywałą szybkością oraz wydajnością działania podczas sterowania robotem. Dodatkowo zastosowanie kontrolera YRC1000 zapewnia osiągnięcie doskonałej precyzji przy programowaniu ścieżki, a przy tych wszystkich zaletach jest maksymalnie kompaktowy, ponieważ całość mieści się  w małej szafie o objętości jedynie 125 litrów i  wadze tylko 70 kilogramów. </p>
                        <ul>
                        <li>Kompaktowość oraz szybkość</li>
                        <li>Globalna standaryzacja ( nie wymaga transformatora)</li>
                        <li>Duża dokładność ścieżki </li>
                        <li>Wysoka efektywność pracy</li>
                        </ul>
                    </div>
                </div>
             </div>

             <div v-show="czypokazac3"><h1>Urządzenie spawalnicze FRONIUS</h1>
                <div class="d-flex mbottom30">
                        <div class="flex-left"><img src="/images/fronius.png" alt="fronius" /></div>
                        <div class="flex-right">
                            <p><b>Urządzenia spawalnicze TPS/i firmy Fronius</b> to systemy klasy premium często też nazywane w świecie spawalniczym systemami przyszłości. Charakteryzują się wysokimi właściwościościami spawania, doskonałą komunikacją na linii człowiek-maszyna oraz modułową konstrukcją dzięki czemu w każdej chwili istnieje możliwość rozszerzenia danego systemu o dodatkowe komponenty. </p>
                            <p class="mbottom15">Urządzenia te dostępne są w klasach o mocy 320, 400, 500 oraz 600 A. </p>
                        </div>
                </div>
                <p><b>Dane techniczne:</b></p>
                <table class="table mytable">
                    <tr>
                        <td></td>
                        <td>TPS 320i</td>
                        <td>TPS 400i</td>
                        <td>TPS 500i</td>
                        <td>TPS 600i</td>
                    </tr>
                    <tr>
                        <td>Prąd spawania maksymalny</td>
                        <td>320 A</td>
                        <td>400 A</td>
                        <td>500 A</td>
                        <td>600 A</td>
                    </tr>
                    <tr>
                        <td>Prąd spawania minimalny </td>
                        <td>3 A</td>
                        <td>3 A</td>
                        <td>3 A</td>
                        <td>3 A</td>
                    </tr>
                    <tr>
                        <td>Prąd spawania / czas włączenia<br><small>[10 min/40oC] [A/40%]</small></td>
                        <td>320</td>
                        <td>400</td>
                        <td>500</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Prąd spawania / czas włączenia<br><small>[10 min/40oC] [A/60%]</small></td>
                        <td>260</td>
                        <td>360</td>
                        <td>430</td>
                        <td>600</td>
                    </tr>
                    <tr>
                        <td>Prąd spawania / czas włączenia<br><small>[10 min/40oC] [A/100%]</small></td>
                        <td>240</td>
                        <td>320</td>
                        <td>360</td>
                        <td>500</td>
                    </tr>
                    <tr>
                        <td>Napięcie biegu jałowego</td>
                        <td>73 V</td>
                        <td>73 V</td>
                        <td>71 V</td>
                        <td>74 V</td>
                    </tr>
                    <tr>
                        <td>Napięcie sieciowe (± 15%)</td>
                        <td>3x400 V</td>
                        <td>3x400 V</td>
                        <td>3x400 V</td>
                        <td>3x400 V</td>
                    </tr>
                    <tr>
                        <td>Wymiary długo</td>
                        <td>706 mm</td>
                        <td>706 mm</td>
                        <td>706 mm</td>
                        <td>706 mm</td>
                    </tr>
                    <tr>
                        <td>Stopień ochrony</td>
                        <td>IP23</td>
                        <td>IP23</td>
                        <td>IP23</td>
                        <td>IP23</td>
                    </tr>
                </table>
            </div>

             <div v-show="czypokazac4"><h1>Głowica spawalnicza</h1>
             <p>
                 Dzięki wyposażeniu szóstej osi w dodatkowe przyciski funkcyjne jesteśmy w stanie w trybie ręcznego prowadzenia, programować robota praktycznie bez udziału „Teach Pendanta” . Wystarczy przesunąć ramię robota do punktu, a następnie wybrać jeden z trzech przycisków:</p>
<ul class="mbottom30">
    <li><b>„MOVE”</b> - umożliwia przesunięcie robota do obecnej pozycji bez możliwości spawania,</li>
    <li><b>„WELD”</b> - dodaje wiersz programu w którym robot wykonuje ruch z punktu do punktu wraz z spawaniem,</li>
    <li><b>„CIRCLE”</b> - umożliwia spawanie odcinków okręgu.</li>
</ul>

    <h3 class="text-center"><span style="color: #0066b3">Programowanie</span> nigdy wcześniej nie było tak proste!</h3>
             
             </div>

             <div v-show="czypokazac5"><h1>Akcesoria do stołów spawalniczych</h1>
             <p>W skład akcesoriów do stołów spawalniczych wchodzą:</p>
<ul>
<li class="mbottom15"><b>Zaciski</b> – dzięki zastosowaniu tego narzędzia jesteśmy w stanie niezwykle dokładnie umiejscowić najbardziej skomplikowany detal na stole spawalniczym. Natomiast prosty montaż, a także demontaż pozwala operatorowi na dużą oszczędność czasu.</li>
<li class="mbottom15"><b>Płyty przedłużające</b> – zastosowanie stabilnej powierzchni umożliwia zwiększenie zakresu roboczego, a dzięki uniwersalnej konstrukcji mamy możliwość mocowania jej w dowolnym miejscu poza płytą główną.</li>
<li class="mbottom15"><b>Ochronna ścianka spawalnicza</b> – pozwala użytkownikowi podzielić stół spawalniczy na dwa obszary wedle zapotrzebowania. Ścianka odgradza jeden obszar od drugiego chroniąc je tym samym przed odpryskani pochodzącymi z sąsiedniego miejsca pracy.</li>
<li class="mbottom15"><b>Półki, szuflady i ściany narzędziowe</b> – dają możliwość przechowywania wszystkich niezbędnych elementów w jednym miejscu.</li>
</ul>
             </div>
             <div v-show="czypokazac6"><h1>Stół spawalniczy / Stacja robocza</h1> 
             <p>Stacje robocze firmy Siegmund pozwalają na stworzenie idealnego miejsca pracy. Dzięki modułowemu systemowi stacje te mogą być rozbudowywane w każdy możliwy sposób jedyną barierą jest tak naprawdę wyobraźnia was samych. </p>
<p>Stacje te dostępne są w trzech wariantach, różniących się między sobą szerokością otworów:</p>
<ul>
<li><b>System 16</b> o średnicy otworu równej 16 mm </li>
<li><b>System 22</b> o średnicy otworu równej 22 mm </li>
<li><b>System 28</b> o średnicy otworu równej 28 mm </li>
</ul>
             </div>
        </div>
    </transition>
</template>

<script>
   export default {
  name: 'PunktyComponent',
   props: {
    punkt: {      
      required: true,
    },
  }, 
  methods: {

  },
  computed: {
      czypokazac1() {
          return this.punkt===1;
      },
      czypokazac2() {
          return this.punkt===2;
      },
      czypokazac3() {
          return this.punkt===3;
      },
      czypokazac4() {
          return this.punkt===4;
      },
      czypokazac5() {
          return this.punkt===5;
      },
      czypokazac6() {
          return this.punkt===6;
      },
  },
 
  data: () => ({
   
  }),
}
</script>

<style scoped>
.d-flex {
    display: flex; flex-wrap: wrap
}
.flex-left {
    width: 25%;
}
.flex-left img {
    display: block; width: 100%; height: auto;
}
.flex-right {
    width: 75%;
    padding-left: 30px;
}
.mytable {margin:0; padding:0; cellspacing: 0; cellpadding: 0; margin-bottom: 60px;}
.mytable tr td { background: rgba(166, 175, 188, .2); padding: 10px 10px}
.mytable tr:nth-child(odd)  td { background: none}
.mytable tr.lastrow td {margin-bottom: 30px;}
.mytable tr.sep td {height: 2px; background: #000; padding:0; margin: 0;}

h2 span { display: block; font-size: 26px;}

.covid {
    background: #d3292c;
    width: 180px;
    height: 180px;
    padding: 10px;
    border-radius: 50%;
    color: #FFF; 
    margin: 0px auto; margin-bottom: 30px;
}
.covid div {
}

.covid div path { fill: #FFF;}

.finans-box {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    position: fixed;
    left:0;
    top: 0;
    z-index:55;
    background: rgba(255,255,255, .95);
    box-shadow: 0 0 30px rgba(166, 175, 188, .3);
    color: #000;
    padding: 30px;
}
@media (min-width: 768px) {
    .covinf {font-size: 17px; text-align: center;}
    .finans-box {
        width: 80%;        
    }
    .finans-box h2 { font-size: 30px;}
    .finans-box h2 small { font-size: 28px; font-weight: 300; color: #0065b2; } 
}

@media (min-width: 990px) {
    .finans-box {
        width: 55%;        
    }   
    .finans-box h2 { font-size: 40px;} 
    .finans-box h2 small { font-size: 28px; font-weight: 300; color: #0065b2; } 
}

@media (min-width: 990px) {
    .finans-box {
        padding: 40px 60px;
    }    
}

.close-modal {
    position: absolute;
    right: 30px; top: 30px;
    cursor: pointer
}
.close-modal:hover path {
    fill: #0065b2; 
}

</style>