<template>
  <transition name="wslide" appear>
        <div class="finans-box">
            <span class="close-modal" @click="$emit('closeModal')">
                <svg version="1.1" viewBox="0 0 512.001 512.001" width="24" xml:space="preserve">
                    <g>
                        <path d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717
                            L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859
                            c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287
                            l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285
                            L284.286,256.002z"/>
                    </g>
                </svg>
            </span>
           <h2><small>Twoja</small><br>Kalkulacja Finansowa</h2>

           <div class="calc-box">  
               <div class="fott" style="display: none;">FOTO</div>
               <div class="calc-center">
                    <div class="d-flex">
                        <div class="calc-center-left">
                            <h3>Wybierz pakiet</h3>
                            <select class="form-control" v-model="pakiet">
                                <option value="0">3000 STARTER</option>
                                <option value="1">PRO-5000 STANDARD</option>
                                <option value="2">PRO-7000 EXPANDED</option>
                                <option value="3">PRO-9000 ULTIMATE</option>
                            </select>
                        </div>
                        <div class="sklad">
                                
                        </div>
                    </div>

                    <hr>

                    <div class="d-flex">
                        <div class="calc-center-left">
                                <h3>Wpłata wstępna:<span>{{ wstepna }} %</span></h3>

                                <div class="slidecontainer">
                                    <input v-model="wstepna" type="range" min="10" max="100" step="10" class="slider">
                                </div>
    
                                <h3>Ilość rat leasingowych:<span>{{ ilerat }}</span></h3>
                                <div class="slidecontainer">
                                    <input v-model="ilerat" type="range" min="12" max="72" step="1" class="slider">
                                </div>
                                
                        </div>
                        <div class="sklad">
                            <div class="sklad-wynik mbottom15">
                                    <div class="mbottom15">Pierwsza wpłata: {{ pierwsza | zlotowki }}*</div>
                                    <div>Twoja miesięczna rata leasingowa: <b>od {{ rata | zlotowki }}*</b></div>
                            </div>  
                            <p>* obliczona orientacyjna kwota nie stanowi oferty handlowej. Cena ulega zmianie w zależności od aktualnego kursu Euro. Podane kwoty netto nie zawierają podatku VAT.</p>                              
                        </div>
                    </div>


               </div>
            </div>

            <div class="text-center">
                <hr>
                <h2>
                    Aby uzyskać szczegółowe informacje,<br>prosimy o kontakt z naszym doradcą
                </h2>
            </div>



              

           


        </div>
    </transition>
</template>

<script>
    export default {
        name: 'FinansowyComponent', 
        data() {
            return {
                ilerat: 36,
                wstepna: 50,
                pakiet: 1,
                
            }
        },
        computed: {

            cena() {
                if (parseInt(this.pakiet)===0) {
                    return 189000;
                } else if (parseInt(this.pakiet)===1) {
                    return 260000;
                } else if (parseInt(this.pakiet)===2) {
                    return 340000;
                } 
                return 390000;                           
            },

            pierwsza() {
                return this.cena * this.wstepna / 100;
            },
            rata() {
                return (this.cena - this.pierwsza) / this.ilerat;
            },
        },
        filters: {
            zlotowki: function (val) {
                return val.toFixed(2) + ' zł';
            }
        }
    }
</script>

<style scoped>

.calc-box {
    display: flex;
}
.calc-box .fott {
    width: 25%; height: 420px;
    background: #EEE;
}
.calc-box .calc-center {
    width: 100%;
}
.d-flex {
    display: flex;
}

.calc-box .calc-center h3 {
    font-size: 15px; font-weight: 400
}
.sklad {padding: 15px; padding-right: 0; padding-top: 30px; width: 50%;}
.sklad-wynik {
    /* background: #0065b2; */
    background: #2f363a;
    padding: 30px;
    padding-left: 15px;
    color: #e1e7f0;
    text-align: right
}
.sklad-wynik b {
    display: block;
    font-size: 44px;
    color: #FFF;
}

.calc-center-left h3 span {
    float: right;
    font-size: 24px;
    color: #0065b2;
    font-weight: 700;
}

.d-flex .calc-center-left {
    width: 50%;
    padding: 15px;
}

.finans-box {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    position: fixed;
    left:0;
    top: 0;
    z-index:55;
    background: rgba(255,255,255, .95);
    box-shadow: 0 0 30px rgba(166, 175, 188, .3);
    color: #000;
    padding: 30px;
}
@media (min-width: 768px) {
    .finans-box {
        width: 80%;        
    }
    .finans-box h2 { font-size: 30px;}
    .finans-box h2 small { font-size: 28px; font-weight: 300; color: #0065b2; } 
}

@media (min-width: 990px) {
    .finans-box {
        width: 55%;        
    }   
    .finans-box h2 { font-size: 40px;} 
    .finans-box h2 small { font-size: 28px; font-weight: 300; color: #0065b2; } 
}

@media (min-width: 990px) {
    .finans-box {
        padding: 40px 60px;
    }    
}

.close-modal {
    position: absolute;
    right: 30px; top: 30px;
    cursor: pointer
}
.close-modal:hover path {
    fill: #0065b2; 
}

.slidecontainer {
  width: 100%; /* Width of the outside container */
  margin-bottom: 30px;
}

/* The slider itself */
.slider {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 30px; /* Specified height */
  background: #e1e7f0; /* Grey background */
  outline: none; /* Remove outline */

  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 30px; /* Set a specific slider handle width */
  height: 30px; /* Slider handle height */
  background: #0065b2; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 0px;  
  border: none;
}

.slider::-moz-range-thumb {
  width: 30px; /* Set a specific slider handle width */
  height: 30px; /* Slider handle height */
  background: #0065b2; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 0px; 
  border: none;
}



</style>