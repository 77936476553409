<template>
  <div>
    <p class="pyt"><strong>4.</strong>Szacunkowa całkowita długość spoiny ( suma długości spoin na jednym detalu )</p>
    <div class="inputtxtbox">
      <input type="text" v-model="odp" class="form-control" placeholder="0" ref="input"> <span>mm</span>
    </div>
    <div class="dalejbox" v-show="gotowe"><button class="btn btn-primary" @click="setOdp">Dalej</button></div> 
  </div>
</template>
<script>
export default {
  name: 'Pytanie3Component',
  data() {
      return {
          odp: '',
      }
  },
  methods: {
    setOdp: function() {
      if (Number.isInteger(parseInt(this.odp)))
      this.$emit('clicked', this.odp);      
    },
    setFocus: function() {
      this.$refs.input.focus();
    }

  },
  computed: {
    gotowe() {
      return this.odp>0;
    }
  },
  mounted: function() {    
        this.setFocus();   
  }
}
</script>