<template>
  <div>
    <p class="pyt"><strong>5.</strong>Łączny czas przygotowania 
elementu – pobranie elementu, odpowiednie ustawienie, zmiana położenia 
elementu przy każdej nowej pozycji dogodnej dla spawacza</p>
    <div class="inputtxtbox">
        <input type="text" v-model="odp" class="form-control" placeholder="0" ref="input"> <span>sekund</span>
    </div>
    <div class="dalejbox" v-show="gotowe"><button class="btn btn-primary" @click="setOdp">Dalej</button></div>      
  </div>
</template>
<script>
export default {
  name: 'Pytanie4Component',
  data() {
      return {
          odp: '',
      }
  },
   computed: {
    gotowe() {
      return this.odp>0;
    }
  },
  methods: {
    setOdp: function() {
      if (Number.isInteger(parseInt(this.odp)))
      this.$emit('clicked', this.odp);
    },
    setFocus: function() {
      this.$refs.input.focus();
    }
  },
  mounted: function() {    
        this.setFocus();   
  }
}
</script>