<template>
  <div>
    <p class="pyt"><strong>6.</strong>Łączna liczba czasu przerwy pracownika w ciągu jednej zmiany</p>
    <div class="inputtxtbox">
        <input type="text" v-model="odp" class="form-control" placeholder="0" ref="input"> <span>h</span>
    </div>
    <div class="dalejbox" v-show="gotowe"><button class="btn btn-primary" @click="setOdp">Dalej</button></div>      
  </div>
</template>
<script>
export default {
  name: 'Pytanie5Component',
   data() {
      return {
          odp: 0.5,
      }
  },
   computed: {
    gotowe() {
      return this.odp>0;
    }
  },
  methods: {
    setOdp: function() {
      this.$emit('clicked', this.odp);
    },
    setFocus: function() {
      this.$refs.input.focus();
    }
  },
  mounted: function() {    
        this.setFocus();   
  }
}
</script>