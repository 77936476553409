<template>
  <div>
    <p class="pyt"><strong>2.</strong>Wybierz rodzaj połączeń spawanych</p>
    <ul class="p2ul">
      <li @click="choose(1)"><img src="/images/krok2-0.jpg" alt="spoina czołowa kwadratowa"><span>spoina czołowa kwadratowa</span></li>
      <li @click="choose(2)"><img src="/images/krok2-1.jpg" alt="spoina czołowa jednostronna ½ Y"><span>spoina czołowa jednostronna ½ Y</span></li>
      <li @click="choose(3)"><img src="/images/krok2-2.jpg" alt="spoina czołowa jednostronna Y"><span>spoina czołowa jednostronna Y</span></li>
      <li @click="choose(4)"><img src="/images/krok2-3.jpg" alt="spoina czołowa dwustronna K"><span>spoina czołowa dwustronna K</span></li>
      <li @click="choose(5)"><img src="/images/krok2-4.jpg" alt="spoina czołowa dwustronna K (zmienna)"><span>spoina czołowa dwustronna K (zmienna)</span></li>
      <li @click="choose(6)"><img src="/images/krok2-5.jpg" alt="spoina czołowa jednostronna V"><span>spoina czołowa jednostronna V</span></li>
      <li @click="choose(7)"><img src="/images/krok2-6.jpg" alt="spoina czołowa dwustronna X"><span>spoina czołowa dwustronna X</span></li>
      <li @click="choose(8)"><img src="/images/krok2-7.jpg" alt="spoina czołowa dwustronna 2/3 X"><span>spoina czołowa dwustronna 2/3 X</span></li>
      <li @click="choose(9)"><img src="/images/krok2-8.jpg" alt="spoina czołowa dwustronna X (zmienna)"><span>spoina czołowa dwustronna X (zmienna)</span></li>
      <li @click="choose(10)"><img src="/images/krok2-9.jpg" alt="spoina czołowa jednostronna U"><span>spoina czołowa jednostronna U</span></li>      
      <li @click="choose(11)"><img src="/images/krok2-10.jpg" alt="spoina czołowa dwustronna U"><span>spoina czołowa dwustronna U</span></li>
      <li @click="choose(12)"><img src="/images/krok2-11.jpg" alt="spoina czołowa jednostronna VU"><span>spoina czołowa jednostronna VU</span></li>
      <li @click="choose(13)"><img src="/images/krok2-12.jpg" alt="spoina czołowa V o stromych brzegach"><span>spoina czołowa V o stromych brzegach</span></li>
      <li @click="choose(14)"><img src="/images/krok2-13.jpg" alt="spoina pachwinowa"><span>spoina pachwinowa</span></li>

    </ul>
  </div>
</template>
<script>
export default {
  name: 'Pytanie1Component',
  methods: {
    choose(odp) {
      this.$emit('clicked', odp);
    }
  }
}
</script>

<style scoped>
.p2ul {
  margin: 0; padding: 0; list-style: none;
  display: flex; flex-wrap: wrap;
  padding-top: 0px;
}
.p2ul li {
  margin: 0; padding: 0; list-style: none;
  width: 33%;
  text-align: center;
  font-size: 13px;
  padding: 7px;
  font-weight: 300;
  cursor: pointer;
}
@media (min-width: 768px) {
  .p2ul { padding-top: 15px; }
  .p2ul li { width: 25%; padding: 15px;}
}
.p2ul li img {
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
  opacity: .85;
  width: 70px;
  height: auto;
}
.p2ul li:hover {color: #007cba}
.p2ul li:hover img {
  opacity: 1;
  box-shadow: 0 0 30px rgba(166, 175, 188, .3);
}
</style>