<template>
    <div class="slogan">
      <div>
      <transition name="wyprz" @after-enter="krok1=true"  appear>        
        <p class="showwyprzedz" v-if="showwyprzedz">Wyprzedź konkurencję</p>
      </transition>
      </div>
      <transition name="pierwszy" @after-enter="krok2=true">        
        <h2 v-if="krok1">Zrób pierwszy krok</h2>
      </transition>
      <transition name="drugi">        
        <b v-if="krok2" class="wfirmie">do zrobotyzowania pracy w swojej firmie</b>
      </transition>
    </div>
</template>

<script>
export default {
  name: 'WyprzedzComponent',
  data() {
      return {
          showwyprzedz: false,
          krok1: false,
          krok2: false
      }
  },
  methods: {
    afterEnter() {
      this.krok1 = true;
    }
  },
  created() {
    this.showwyprzedz = true;
  }
}
</script>

<style scoped>

.showwyprzedz {
  position: relative; 
  left: -40px;
}

.wyprz-enter-active {
    transition: all 1s ease; 
}

.wyprz-enter {      
  transform: translateX(-200px);
  opacity: 0
}

.wyprz-enter-to {    
}

.pierwszy-enter-active {
    transition: all 1.5s ease; 
}

.pierwszy-enter {      
  transform: translateX(120px);
  opacity: 0
}

.drugi-enter-active {
    transition: all 3s ease; 
}

.drugi-enter {        
  opacity: 0;
  transform: translateX(-230px);
}









.wfirmie {
  font-size: 14px;
  font-weight: 700;
  color: #0066b3
}
  .fade-enter-active, .fade-leave-active {
    transition: all 3s;    
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateX(-50px);
  }
  .fade-enter-to, .fade-leave /* .fade-leave-active below version 2.1.8 */ {
    opacity: 1;
    transform: translateX(0px);
  }

  .fade-enter-active {
    transition: all 3s;    
  }
  .fade-enter {
    opacity: 0;
    transform: translateX(-50px);
  }
  .fade-enter-to{
    opacity: 1;
    transform: translateX(0px);
  }

@media (min-width: 768px) { 
  .slogan {
    margin-bottom: 30px; 
    height: 120px;    
  }
  .wfirmie {
    font-size: 18px;
    
  }
}

@media (min-width: 990px) {
  .wfirmie {
    font-size: 24px;
  }


}

</style>