<template>
    <transition name="wslide" appear>
        <div class="rpi-box">
            <span class="close-modal" @click="$emit('closeModal')">
                <svg version="1.1" viewBox="0 0 512.001 512.001" width="24" xml:space="preserve">
                    <g>
                        <path d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717
                            L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859
                            c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287
                            l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285
                            L284.286,256.002z"/>
                    </g>
                </svg>
            </span>
           <h2>Zysk produktywności<br><small>z zastosowaniem robota współpracującego</small></h2>

           <div v-if="showPath" class="points">
               
               <div :class="[{ 'point-active': displayxPyt0}, 'point', 'point-0']" @click="gotoPyt(0)" title="1"></div> 
               <div :class="[{ 'point-active': displayxPyt1}, 'point', 'point-1']" @click="gotoPyt(1)" title="2"></div> 
               <div :class="[{ 'point-active': displayxPyt2}, 'point', 'point-2']" @click="gotoPyt(2)" title="3"></div> 
               <div :class="[{ 'point-active': displayxPyt3}, 'point', 'point-3']" @click="gotoPyt(3)" title="4"></div> 
               <div :class="[{ 'point-active': displayxPyt4}, 'point', 'point-4']" @click="gotoPyt(4)" title="5"></div>  
               <div :class="[{ 'point-active': displayxPyt5}, 'point', 'point-5']" @click="gotoPyt(5)" title="6"></div> 
               <div :class="[{ 'point-active': displayxPyt6}, 'point', 'point-6']" @click="gotoPyt(6)" title="7"></div> 
               
               
           </div>

           <Pytanie0Component v-if="displayPyt0" @clicked="setOdp" />
           <Pytanie1Component v-if="displayPyt1" @clicked="setOdp"/>
           <Pytanie2Component v-if="displayPyt2" @clicked="setOdp"/>
           <Pytanie3Component v-if="displayPyt3" @clicked="setOdp"/>
           <Pytanie4Component v-if="displayPyt4" @clicked="setOdp"/>
           <Pytanie5Component v-if="displayPyt5" @clicked="setOdp"/>
           <Pytanie6Component v-if="displayPyt6" @clicked="Obliczam"/>
           
           <WynikComponent v-if="wynik" :rpi="rpi" :spawacz="spawacz" :robot="robot" :odpowiedzi="odpowiedzi" @ponownie="handlePonownie" @finansowy="handleFinansowy" />

           <!-- <div v-if="wynik">
               <wynik-component />
               <podsumowanie-component />
           </div> -->


        </div>
    </transition>
</template>

<script>

import Pytanie0Component from '@/components/Pytanie0Component.vue'
import Pytanie1Component from '@/components/Pytanie1Component.vue'
import Pytanie2Component from '@/components/Pytanie2Component.vue'
import Pytanie3Component from '@/components/Pytanie3Component.vue'
import Pytanie4Component from '@/components/Pytanie4Component.vue'
import Pytanie5Component from '@/components/Pytanie5Component.vue'
import Pytanie6Component from '@/components/Pytanie6Component.vue'

import WynikComponent from '@/components/WynikComponent.vue'
/* import PodsumowanieComponent from '@/components/PodsumowanieComponent.vue' */



export default {
  name: 'RpiComponent',
    components: {
    Pytanie0Component, Pytanie1Component, Pytanie2Component, Pytanie3Component, Pytanie4Component, Pytanie5Component, Pytanie6Component, WynikComponent
    },
  data() {
      return {
          currentpyt: 0,
          displayPyt: [true, false, false, false, false, false, false],
          odpowiedzi: [null, null, null, null, null, 0.5, 8],
          wynik:false,
          showPath: true,
          spawacz: 0,
          robot: 0,
          ws: {
            0: {
                1: 21.60,
                2: 18.30,
                3: 15.80,
                4: 14.15,
                6: 12.50,
                8: 11.30,
                10: 10.15,
                12: 9,
                14: 7.80,
                16: 6.65,
                18: 5.80,
                20: 5
            },
            1: {
                1: 6.60,
                2: 5.80,
                3: 5.10,
                4: 4.50,
                6: 3.90,
                8: 3.40,
                10: 2.90,
                12: 2.50,
                14: 2.20,
                16: 1.80,
                18: 1.50,
                20: 1.1
                }
            }
      }  
  },
   
  computed: {
    displayPyt0: function() { return 0 === this.currentpyt },
    displayPyt1: function() { return 1 === this.currentpyt },
    displayPyt2: function() { return 2 === this.currentpyt },
    displayPyt3: function() { return 3 === this.currentpyt },
    displayPyt4: function() { return 4 === this.currentpyt },
    displayPyt5: function() { return 5 === this.currentpyt },
    displayPyt6: function() { return 6 === this.currentpyt },
    
    displayxPyt0: function() { return 0 <= this.currentpyt },
    displayxPyt1: function() { return 1 <= this.currentpyt },
    displayxPyt2: function() { return 2 <= this.currentpyt },
    displayxPyt3: function() { return 3 <= this.currentpyt },
    displayxPyt4: function() { return 4 <= this.currentpyt },
    displayxPyt5: function() { return 5 <= this.currentpyt },
    displayxPyt6: function() { return 6 <= this.currentpyt },

    rpi: function() {
        if (this.spawacz>0) return (this.robot / this.spawacz).toFixed(2);
        return 0;
    }   
  },
      
   methods: {
    handleFinansowy() {
        this.$emit('finanse');
    },
    handlePonownie() {
      this.gotoPyt(0);
      this.wynik = false;
      this.showPath = true;
    },
    checkDisplayPytComponent(pyt) {
        return pyt === this.currentpyt
    },  
    gotoPyt(pyt) {
        if (this.currentpyt >= pyt) {
            this.displayPyt[this.currentpyt] = false;
            this.currentpyt = pyt;
            this.displayPyt[this.currentpyt] = true;     
        }        
    },    
    setOdp(value) {
      this.odpowiedzi[this.currentpyt] = value;
      this.displayPyt[this.currentpyt] = false;
      this.displayPyt[this.currentpyt++] = true; 
    },
    Obliczam() {
        let weldSpeed = this.ws[this.odpowiedzi[0]][this.odpowiedzi[2]]
        this.spawacz = this.obliczSpawacza(parseInt(this.odpowiedzi[3]), weldSpeed, parseInt(this.odpowiedzi[4]), this.odpowiedzi[5], this.odpowiedzi[6]);                
        this.robot =this.obliczRobota(this.odpowiedzi[3], weldSpeed, this.odpowiedzi[4], this.odpowiedzi[6]);        
        this.currentpyt = 7;
        this.displayxPyt0
        this.showPath = false;
        this.wynik = true;
    },

    obliczSpawacza: (lenght, weldSpeed, timePreper, breakeTime, zmiana) => {
        
        console.log('lenght'+lenght);
        console.log('weldSpeed'+weldSpeed);
        console.log('timePreper'+timePreper);

        let xtime = 6 - breakeTime;  
        let noipw = 3600 / ((lenght / weldSpeed) + timePreper);        

        noipw = noipw * xtime;           
    
        let sum1 = (lenght / weldSpeed) + timePreper;
        let sum2 = sum1 * 0.3;
        let noipw2 = 3600 / ( sum1 + sum2 );
        let xtime2 = zmiana - 6;

        noipw2 = noipw2 * xtime2        
        
        const total = noipw + noipw2;
        return parseInt(total);
    },

    obliczRobota: (lenght, weldSpeed, timePreper, zmiana) => {
        let totalTP = timePreper - (timePreper * 0.3);
        let noipr = 3600 / ((lenght / weldSpeed) + totalTP);
        noipr = noipr * zmiana;
        return parseInt(noipr);
    }
  }
}
</script>

<style>

.wslide-enter-active {
    transition: all .5s ease; 
}

.wslide-enter {      
  transform: translateX(-4000px);
}

.wslide-enter-to {
    
}


.wslide-leave-active {
  transition: all .5s ease; 
}

.wslide-leave {

}

.wslide-leave-to {
  transform: translateX(-4000px);
}

</style>

<style scoped>



.rpi-box h2 { font-weight: 700; margin-bottom: 0; margin-top: 0px;}
.rpi-box h2 small { font-size: 15px; font-weight: 300; color: #0065b2; display: block}


.rpi-box {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    position: fixed;
    left:0;
    top: 0;
    z-index:55;
    background: rgba(255,255,255, .95);
    box-shadow: 0 0 30px rgba(166, 175, 188, .3);
    color: #000;
    padding: 30px;
}
@media (min-width: 768px) {
    .rpi-box {
        width: 80%;
    }
    .rpi-box h2 { font-size: 30px;}
    .rpi-box h2 small { font-size: 20px }
}

@media (min-width: 990px) {
    .rpi-box {
        width: 55%;        
    }
    .rpi-box h2 { font-size: 40px;}
    .rpi-box h2 small { font-size: 28px }
}

@media (min-width: 990px) {
    .rpi-box {
        padding: 40px 60px;
    }
}

.close-modal {
    position: absolute;
    right: 30px; top: 30px;
    cursor: pointer
}
.close-modal:hover path {
    fill: #0065b2; 
}

.points {
    display: flex;
    padding: 30px 0;
}
.points .point {
    width: 14.28%;
    height: 15px; background: #DDD;
}
.points .point-active {
    background: #0065b2 !important;
    cursor: pointer; 
}
.points .point-active:hover {
    background: #0995db !important;
}
</style>