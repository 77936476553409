<template>
  <div>
    <p class="pyt zeropyt text-center mbottom30 ptop15">Wybierz metodę spawania:</p>

    <ul class="pyt1-images">
      <li @click="choose(0)"><img src="/images/mig.jpg" alt="metoda MIG/MAG"><div></div><span>MIG/MAG</span></li>
      <li @click="choose(1)"><img src="/images/tig.jpg" alt="metoda TIG"><div></div><span>TIG</span></li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'Pytanie0Component',
 methods: {
    choose(odp) {
      this.$emit('clicked', odp);
    }
  }
}
</script>
<style>
.ptop15 {
  padding-top: 15px;
}
.zeropyt {
  font-size: 20px;
  font-weight: 700
}
.pyt1-images, .pyt1-images li {
  margin: 0;
  padding:0;
  list-style: none;
}
.pyt1-images {
  display: flex;
  justify-content: center;
}
.pyt1-images li {
  width: 40%;
  overflow: hidden;
  position: relative;
  top:0; left:0;
  cursor: pointer;
  margin: 10px;
}
.pyt1-images li img {
  display: block;
  width: 100%;
  height: auto;
  transition: all 0.5s linear;
}
.pyt1-images li:hover img {
  transform: scale(1.2);
}
.pyt1-images li div {
  position: absolute;
  left:0;
  top:0;
  width: 100%;
  height: 100%;
  background: rgba(0,124,186,.4);
  transition: all .5s linear;
}
.pyt1-images li:hover div {
  background: rgba(0,124,186,.1);
}
.pyt1-images li span {
  position: absolute; left:  0;
  top: 50%;
  width: 100%; 
  color: #FFF;
  line-height: 1em;
  font-weight: 700;
  font-size: 22px;
  display: block;
  transform: translateY(-50%);
  text-align: center;
}
@media (min-width: 768px) {
  .pyt1-images li span {
    font-size: 38px;
  }
}
@media (min-width: 990px) {
  .pyt1-images li span {
    font-size: 44px;
  }
}
</style>