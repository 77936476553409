<template>
  <div id="app">

      <div id="homep">
        <div class="container">
            <div id="mainpage"> 
                <div class="logoyaskawabox">
                    <a target="_blank" href="https://yaskawa.pl"><img src="https://weld4me.pl/images/l_yaskawa.png" alt="Yaskawa"></a>
                    <a target="_blank" href=" https://www.fronius.com/pl-pl/poland/spawalnictwo"><img src="https://weld4me.pl/images/l_fronius.png" alt="Yaskawa"></a>
                    <a href="#"><img src="https://weld4me.pl/images/l_siegmund.png" alt="Yaskawa"></a>
                </div>

                <logoComponent />
                <div id="robot" style="">
                    <div class="" style="width: 100%; display: flex; justify-content: right">
                        <div style="width: 100%;" class=""></div>
                        <div class="robotbox"><div class="blob blobhidden point1" @click="openPoints(1)"></div><div class="blob blobhidden point2" @click="openPoints(2)"></div><div class="blob blobhidden point3" @click="openPoints(3)"></div><div class="blob blobhidden point4" @click="openPoints(4)"></div><div class="blob blobhidden point5" @click="openPoints(5)"></div><div class="blob blobhidden point6" @click="openPoints(6)"></div><img src="images/robot.png" alt="ROBOT"></div>
                    </div>
                </div>
                <div id="box-zalety">
                    <h4 class="text-center">Zobacz jak to działa:</h4>
                    <a target="_blank" href="https://www.youtube.com/watch?v=9-8LcMHGyPc&t=61s" id="showmovie"><img src="images/film.jpg" alt="zobacz film"></a>
                </div>

                <div id="box-info">
                    <div id="box-info-content">
                        <WyprzedzComponent />
                        <div class="whitebox">
                            <h3 class="text-center">Umów się</h3>
                            <p class="text-center text-bold mbottom15">na <strong>bezpłatną prezentację</strong> możliwości<br>robota współpracującego<br><small>w dowolnym miejscu i czasie*</small></p>

                    <ContactformComponent @runPolityka="politykaModal=true" @runcovid="covidModal=true" />
                            
                        </div>

                        <div id="box-zalety-mobile">
                            <h4 class="text-center">Zobacz jak to działa:</h4>
                            <a href="https://www.youtube.com/watch?v=9-8LcMHGyPc&t=61s" data-lity="" id="showmovie"><img src="images/film.jpg" alt="zobacz film"></a>
                        </div>


                    </div>
                </div>


            </div>
        </div>
    </div>



 <div id="pasek">
        <div class="container">
            <div class="row">
                <div class="col-sm-6 col-lg-8">
                    <h3>Oblicz zysk produktywności<small>z zastosowaniem robota współpracującego korzystając z naszego narzędzia<br>wskaźnika produktywności robota (RPI)*</small></h3>
                    

                    <div class="row">
                        <div class="col-sm-6 display-md-flex">
                            <button class="btn btn-info mright15" @click="rpiModal=true">Oblicz teraz</button> <button class="btn btn-primary" @click="finanseModal=true">Kalkulator finansowy</button>                                                        
                        </div>                        
                        <div class="col-sm-6 text-right">

                        </div>
                    </div>
                    <p class="wynikinfo">* - wynik zostanie podany z uwzględnieniem normatywy spawalniczej dla metod TIG i MIG/MAG</p>
                   
                </div>
            </div>
        </div>
    </div>

    <PolitykaComponent v-if="politykaModal" @closeModal="politykaModal=false" />
    <PunktyComponent v-if="pointsModal" :punkt="wybrany" @closeModal="pointsModal=false" />

    <RpiComponent v-if="rpiModal" @closeModal="rpiModal=false" @finanse="handleFinanse" />
    <finansowy-component v-if="finanseModal" @closeModal="finanseModal=false" />

    <covid-component v-if="covidModal" @closeModal="covidModal=false" />

    
    
    <div class="robotmobilewrap"><div class="robotbox robotmobile"><div class="blob blobhidden point1" @click="openPoints(1)"></div><div class="blob blobhidden point2" @click="openPoints(2)"></div><div class="blob blobhidden point3" @click="openPoints(3)"></div><div class="blob blobhidden point4" @click="openPoints(4)"></div><div class="blob blobhidden point5" @click="openPoints(5)"></div><div class="blob blobhidden point6" @click="openPoints(6)"></div><img src="images/robot.png" alt="ROBOT"></div></div>
                    

    <div class="dzialamy"><span>15 lat</span> w branży robotyzacji i automatyki przemysłowej</div>

       <footer>
        <div class="container">
            <div class="row">
                <div class="col-sm-4 col-lg-3" id="signat">
                    <!-- <img src="images/rls.png" alt="RLS"> -->
                    <p>Robotpol</p>
                    <p>ul. Myśliwska 1<br>83-315 Kłobuczyno</p>
                </div>
                <div class="col-sm-4 col-lg-3" id="footer-contact">
                    <p>Kontakt</p>
                    <p>tel.: +48 600 570 730<br>e-mail: <a href="mailto:weld4me@robotpol.pl">weld4me@robotpol.pl</a><br><a title="robotpol.pl" target="_blank" href="https://robotpol.pl">robotpol.pl</a></p>
                </div>
                <div class="col-sm-4 col-lg-6 text-right">                    
                    <div class="ptop10"><a rel="nofollow" target="_blank" href="https://yaskawa.pl"><img src="images/yaskawawhite.png" alt="YASKAWA"></a></div>
                </div>
            </div>


        </div>
		<div class="cookiebox" v-show="isCookies">Witryna wykorzystuje ciasteczka (ang. cookies) w celach sesyjnych oraz statystycznych. Więcej informacji w polityce prywatności.  <button @click="ciacha=false">zamknij</button></div>
    </footer>
  
  </div>
</template>

<script>
import LogoComponent from './components/LogoComponent.vue'
import ContactformComponent from './components/ContactformComponent.vue'
import WyprzedzComponent from './components/WyprzedzComponent.vue'
import RpiComponent from './components/RpiComponent.vue'
import FinansowyComponent from './components/FinansowyComponent.vue'
import PolitykaComponent from './components/PolitykaComponent.vue'
import CovidComponent from './components/CovidComponent.vue'
import PunktyComponent from './components/PunktyComponent.vue'
  

export default {
  name: 'App',
  components: {
    LogoComponent, ContactformComponent, WyprzedzComponent, RpiComponent,
    FinansowyComponent, PolitykaComponent, PunktyComponent,
    CovidComponent
  },
  data() {
    return {
    rpiModal: false,
    finanseModal: false,
    politykaModal: false,
    covidModal: false,
    ciacha: true,
    pointsModal: false,
    wybrany: 0,
    }
  }, 
  methods: {
      handleFinanse() {
        this.rpiModal = false;
        this.finanseModal = true; 
      },
      openPoints(selected)
      {
          this.wybrany = selected;
          this.pointsModal = true;
      }	
  }, 
 computed: {
	isCookies(){
		return this.ciacha;
	},
 }, 
 created () {
      setTimeout(function() { document.querySelector('#robot div').classList.add('active'); }, 1500); this.ciacha = true;
      setTimeout(function() { 
          const punkty = document.querySelectorAll('.blob');
            punkty.forEach((obj) => obj.classList.remove('blobhidden'));
        }, 2500);
  } 
}
</script>

<style>
.robotmobilewrap {
    padding: 0px 15px 0 15px; 
    background:#e1e7f0;
}
.robotmobile {
    max-width: 440px;
    margin: 0 auto;
    margin: 0;
    padding:0; 
    padding-left: 15px;
    padding-top: 10px;
}
.robotmobile img {
    display: block;
    width: 100%; height: auto;
}

.dzialamy {
    background: #FFF;
    padding: 40px 15px;
    text-align: center;
    color: #2f363a;
    font-size: 36px;
    font-weight: 300;
}
.dzialamy span { font-size: 44px; font-weight: 700; color: #00508f }
.robotbox {
    position: relative; left:0; top:0;
}
.robotbox .blob {
    background:  rgba(211, 41, 44, 1);
	border-radius: 50%;
	margin: 10px;
	height: 24px;
    width: 24px;
    position: absolute; 
    z-index:1;
    cursor: pointer;
	box-shadow: 0 0 0 0 rgba(211, 41, 44, 1);
	transform: scale(1);
    animation: pulse 2s infinite;
    opacity: 1;
    transition: all 1s ease-out;
}
.robotbox .blobhidden {opacity: 0; }
.robotbox .point1 {
    left: 36%;
    top: 28%;
}

.robotbox .point2 {
    left: 85%;
    top: 87%;
}

.robotbox .point3 {
    left: 40%;
    top: 90%;
}

.robotbox .point4 {
    left: 82%;
    top: 40%;
}

.robotbox .point5 {
    left: 62%;
    top: 62%;
}

.robotbox .point6 {
    left: 50%;
    top: 70%;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(211, 41, 44, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(211, 41, 44, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(211, 41, 44, 0);
	}
}

.wynikinfo {
    color: #2f363a;
    padding-top: 15px; 
    padding-left: 14px;
}

.cookiebox {
	text-align: center; 
	padding: 10px;
	position: fixed;
	left:0; bottom: 0;
	width: 100%;
	background: #2f363a;
    color: #EEE;
    z-index: 99
}
.cookiebox button {
	background: transparent linear-gradient(121deg, #0066b2 0%, #00508f 100%) 0% 0% no-repeat padding-box;
	color: #FFF; border-radius: 0; border: none;  padding: 5px 15px; cursor: pointer
}

#app {
    overflow: hidden
}

.inputtxtbox {
    padding: 30px 0;
    margin-bottom: 15px;
    display: flex;
    font-weight: 400;
    align-items: baseline;
    font-size: 20px
}
.inputtxtbox .form-control {
    font-size: 17px;
    width: 50%;
    font-weight: 300;
}

.inputtxtbox [type="text"] {
    width: 150px;
    text-align: center;
    font-size: 24px;
    margin-right: 15px;
    font-weight: 300 !important; 
}
.dalejbox {
    padding-left: 0px;
    padding-top: 0px;
}
@media (min-width: 768px) {

.robotmobilewrap { display: none;}
    #box-zalety-mobile {display: none;}
    #box-info-content {
        padding-top: 0px;
    }

    .inputtxtbox {
        padding: 30px;
    }
    .dalejbox {
        padding-left: 30px;    
        padding-top: 30px;
    }
    .display-md-flex {display: flex;}

    #box-info {
        bottom: -20px;
    }
 
}

@media (max-width: 767px) {

    .calc-center .d-flex { display: block; }
    .calc-center .d-flex .calc-center-left, .calc-center .d-flex .sklad { width: 100%; padding: 15px; margin: 0;}
    .calc-center .d-flex .sklad .sklad-wynik {padding: 15px; width: 100%; text-align: center;}
    .calc-center .d-flex .sklad .sklad-wynik b {font-size: 28px;}

.container { padding: 0 !important}
   #homep { background: #FFF url(https://weld4me.pl/images/mobilebg.jpg) no-repeat center 30px; }
   #mainpage { 
       background: url(https://new.weld4me.pl/images/robot.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: 120px;
  background-position-x: 50px;
    } 
   .slogan { height: 260px; padding-top: 180px; margin-left: 15px; margin-right: 15px; margin-bottom: 15px;  }
   .whitebox {
       width: 90%;
       margin: 0 auto;
       background: rgba(255, 255, 255, .9) !important;
       margin-bottom: 30px;
   }
   #weldlogo {
       padding-left: 15px;
       padding-top: 50px;
   }
   #box-info #box-info-content .slogan p { font-size: 18px; } /* background: rgba(255, 255, 255, .3)} */
   #box-info #box-info-content .slogan h2 { font-size: 28px;  }

   .covid { right: -50px !important}
   .btn-info, .btn { font-size: 18px; padding: 10px 15px;}

   .display-md-flex button {
       display: block;
       width: 90%;
       margin: 0 auto;
       margin-bottom: 20px;
   }
   #box-zalety {display: none;}
   #box-zalety-mobile {
       padding: 30px;
       padding-top: 0;
       margin-bottom: 15px;
       text-align: center;
       color: #00508f;
   }
   #box-zalety-mobile a { display: block; margin: 0 auto; max-width: 320px;}
   #box-zalety-mobile a img { display: block; width: 100%; height: auto;}
   #pasek h3 small {font-size: 16px;}
   .logoyaskawabox {
       width: 100%; padding: 10px 15px;
       background: #FFF;
       position: absolute; left:0; top:0;
   }
   .logoyaskawabox img {
       width: 90%;
       max-width: 340px; 
       display: block;
       height: auto;
       margin: 0 auto;
   }
} 

.logoyaskawabox {
    display: flex;
    z-index: 666;
    justify-content: flex-end;
    margin-bottom: 30px;
}

@media (min-width: 768px) {
    .slogan {
        height: auto; margin: 0 !important; padding-left: 40px;
    }
    #box-info {
        bottom: -150px;
        
    }
    #logoyaskawa {
        width: 36%;
        max-width: 570px;
    }
    .logoyaskawabox {
        padding-top: 15px;
    }
    .logoyaskawabox a {
        height: 30px; display: block;
    }
    .logoyaskawabox img {
        display: block; height: 30px; width: auto;
    }
}

@media (min-width: 990px) {
    #box-info {
        bottom: -150px;
        position: absolute;
    }
     .slogan {
        height: auto; margin-bottom: 30px !important; padding-left:0;
    }
}
</style>
