

<script>
import { Bar } from 'vue-chartjs'
  export default {
  extends: Bar,
  props: {
      xspawacz: {
          required: true
      },
      xrobot: {
          required: true
      }
  },
  mounted () {
    let data= {
        labels: ['Średnia liczba wyprodukowanych detali w ciągu jednej zmiany'],  
        datasets: [{
            label: [ 'Człowiek'],
            data: [this.xspawacz],
            backgroundColor: [
                '#e8052b',                
                
            ],
            borderColor: [
                '#ca0022',                                
            ],
            borderWidth: 1
        },       
        {
            label: [ 'Robot'],
            data: [this.xrobot],
            backgroundColor: [
                '#007cba',                
                
            ],
            borderColor: [
                '#006496',                                
            ],
            borderWidth: 1
        },       
        ]
    };
    let options = {
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
    };
    this.renderChart(data, options)
  }
}
</script>

<style lang="scss" scoped>

</style>