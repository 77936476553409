<template>
  <div>
    <p class="pyt"><strong>3.</strong>Średnia grubość elementu [mm]</p>
    <div class="inputtxtbox">
    <select id="krok3" v-model="odp" data-pyt="3" class="form-control" style="width: 200px;">                    
                    <option value="0" selected="selected" disabled="">wybierz grubość</option>
                    <option value="1">od 1 do 2 mm</option>
                    <option value="2">od 2 do 3 mm</option>
                    <option value="3">od 3 do 4 mm</option>
                    <option value="4">od 4 do 6 mm</option>
                    <option value="6">od 6 do 8 mm</option>
                    <option value="8">od 8 do 10 mm</option>
                    <option value="10">od 10 do 12 mm</option>
                    <option value="12">od 12 do 14 mm</option>
                    <option value="14">od 14 do 16 mm</option>
                    <option value="16">od 16 do 18 mm</option>
                    <option value="18">od 18 do 20 mm</option>
                    <option value="20">powyżej 20 mm</option>
                </select>
                </div>
                <div class="dalejbox" v-show="gotowe"><button class="btn btn-primary" @click="setOdp">Dalej</button></div>
  </div>
</template>
<script>
export default {
  name: 'Pytanie2Component',
  data() {
      return {
          odp: 0,
      }
  },
   computed: {
    gotowe() {
      return this.odp>0;
    }
  },
  methods: {
    setOdp: function() {
      this.$emit('clicked', this.odp);
    }
  }
}
</script>