<template>
  <div id="weldlogo">
            <a href="https://www.yaskawa.pl/systems/system-engineering/productdetail/product/weld4me_10286" target="_blank"><img id="logoweld" src="images/weld4.png"></a>
            <p>Robot przemysłowy współpracujący<br>do łatwego spawania małych serii</p>
        </div>
</template>

<script>
export default {
  name: 'LogoComponent',

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
