<template>
   <div>

     <div class="wsktablebox">
      <div class="wskwrap">
        
  
        <div class="wskbox"> 
          <table class="table">
              <tr>
                  <td>Metoda spawania</td><td v-html="smetoda"></td>
              </tr>
              <tr>
                  <td>Średnia grubość elementu</td><td><span v-text="odp[2]"></span> mm</td>
              </tr>
              <tr>
                  <td>Łączny czas przygotowania elementu</td><td><span v-text="odp[4]"></span> sekund</td>
              </tr>
              <tr>
                  <td>Łączny czas przerwy pracownika</td><td><span v-text="odp[6]"></span> h</td>
              </tr>
          </table>
        </div>

        <div class="wskbox"> 
          <table class="table">
              <tr>
                  <td>Rodzaj połączeń spawanych</td><td></td>
              </tr>
              <tr>
                  <td>Całkowita długość spoiny</td><td><span v-text="odp[3]"></span> mm</td>
              </tr>
              <tr>
                  <td>Czas trwania jednej zmiany spawacza</td><td><span v-text="odp[7]"></span> h</td>
              </tr>
            </table>
        </div>

      </div>
    </div>

      



      <div class="wskwrap">

     



        <div class="wskbox"> 
          

          <div style="max-width: 420px; margin: 0 auto; "><BarComponent :xspawacz="xspawacz" :xrobot="xrobot" /></div>

          
        </div>

      <div class="wskbox">
          <h2 class="text-center mbottom30">Wskaźnik Produktywności Robota<br>wynosi:</h2>
          <div v-text="rpp" class="rpiround"></div>
          <p class="text-center">podany wynik uwzględnia normatywy spawalnicze<br>dla metod TIG i MIG/MAG</p>          
        </div>
      </div>
      <div class="czymjest">
        <h3>Czym jest wskaźnik produktywności robota?</h3>
        <p>Wskaźnik produktywności robota (ang. Robot Productivity Index) określa stosunek ilości detali wyprodukowanych przez robota do ilości detali wyprodukowanych przez spawacza w tym samym czasie.</p>
      </div>

      <div class="bottombox">
          <button class="btn btn-primary" @click="obliczPonownie">Oblicz ponownie</button>
          <button class="btn btn-danger" @click="finansowy">Twój Kalkulator Finansowy</button>
      </div>

   </div>
</template>

<script>
import BarComponent from '@/components/BarComponent.vue'
export default {
  name: 'WynikComponent',
  components: {
    BarComponent
  },
   props: {
    rpi: {      
      required: true,
    },
    spawacz: {
      required: true,
    },
    robot: {
      required: true
    },
    odpowiedzi: {
      required: true
    }
  }, 
  methods: {
    obliczPonownie() {
      this.$emit('ponownie');
    },
    finansowy() {
      this.$emit('finansowy');
    }
  },
  created() {
    this.rpp = this.rpi;  
    this.xspawacz = this.spawacz;  
    this.xrobot = this.robot;  
    this.odp = this.odpowiedzi;
  },
    
  data: () => ({
    rpp: 0,
    xrobot: 0,
    xspawacz: 0,
    odp: {}
  }),
  computed: {
    smetoda() {
      return (this.odp[0]==0)?'MIG/MAG':'TIG';
    }
  }
}
</script>

<style scoped>

.wsktablebox {
  margin-left: -15px;
  margin-right: -15px;
}
.wskwrap {  
  padding: 15px
  
}
.wskbox {
  width: 100%;
  padding: 0px;
}
.rpiround {
    width: 150px;
    height: 150px;
    text-align: center;
    line-height: 150px;
    font-size: 60px;
    color: #0065b2;
    border: 4px solid #0065b2;
    border-radius: 120px;
    margin: 0px auto;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-top: 15px;
    margin-bottom: 30px;
    font-weight: 300;
}
.czymjest {background: #e1e7f0; padding: 15px;}
.czymjest h3 {margin: 0 0 5px 0;}
.table tr {}
.table tr td { border-bottom: 1px solid #e1e7f0; padding: 4px 10px; font-size: 12px;}

 .bottombox {
    padding: 30px 0;
 }

@media (max-width: 767px) {
    .wskbox h2 { font-size: 17px;}
    .bottombox .btn {
      font-size: 18px; display: block; width: 100%;  margin-bottom: 15px; padding: 15px 0;
    }
}

@media (min-width: 768px) {
  .wskbox {
    width: 50%;
    padding: 15px;
  }
  .wskwrap {
    display: flex;
  }
  .bottombox {
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 768px) {
  .wskbox {
    width: 50%;
    padding: 15px;
  }
}


</style>